import { Injectable } from '@angular/core';
import { Confirmation, ConfirmationService, Message, MessageService, PrimeIcons } from 'primeng/api';
import { nanoid } from 'nanoid';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    protected _errors: Message[] = [];
    protected _success: Message[] = [];

    constructor(
        private messageService: MessageService,
        private confirmationService: ConfirmationService
    ) {}

    showError(message: string, sticky?: boolean, life?: number) {
        if (this._errors.some((e) => e.detail === message)) return;
        this.addError(message, sticky, life);
        this.messageService.add({
            key: 'epx-messages',
            severity: 'error',
            detail: message,
            life: life || 3000,
            sticky: sticky || false,
            icon: PrimeIcons.TIMES_CIRCLE
        });
        setTimeout(() => (this._errors = []), 60);
    }

    showWarn(message: string, sticky?: boolean, life?: number) {
        this.messageService.add({
            key: 'epx-messages',
            severity: 'warn',
            detail: message,
            life: life || 3000,
            sticky: sticky || false,
            icon: PrimeIcons.EXCLAMATION_CIRCLE
        });
    }

    showInfo(message: string, sticky?: boolean, life?: number) {
        this.messageService.add({
            key: 'epx-messages',
            severity: 'info',
            detail: message,
            life: life || 3000,
            sticky: sticky || false,
            icon: PrimeIcons.INFO_CIRCLE
        });
    }

    showSuccess(message?: string, sticky?: boolean, life?: number) {
        if (this._success.some((e) => e.detail === message)) return;
        this.addSuccess(message, sticky, life);
        this.messageService.add({
            key: 'epx-messages',
            severity: 'success',
            detail: message || 'GENERAL.TOAST.SUCCESS',
            life: life || 1500,
            sticky: sticky || false,
            closable: sticky || false,
            icon: PrimeIcons.CHECK_CIRCLE
        });
        setTimeout(() => (this._success = []), 60);
    }

    confirmDialog(config: Confirmation) {
        this.confirmationService.confirm({
            target: config.target,
            key: config.key || 'epx-confirm',
            message: config.message,
            header: config.header,
            icon: 'PrimeIcons.EXCLAMATION_TRIANGLE',
            acceptLabel: config.acceptLabel,
            rejectLabel: config.rejectLabel,
            accept: config.accept,
            reject: config.reject,
            acceptButtonStyleClass: 'epx-button',
            rejectButtonStyleClass: 'epx-button epx-button-text'
        });
    }

    protected addError(message: string, sticky?: boolean, life?: number) {
        const error: Message = {
            id: nanoid(),
            detail: message,
            sticky: sticky || false,
            life: life || 3000
        };
        this._errors.push(error);
    }

    protected addSuccess(message?: string, sticky?: boolean, life?: number) {
        const success: Message = {
            id: nanoid(),
            detail: message || 'GENERAL.TOAST.SUCCESS',
            sticky: sticky || false,
            life: life || 3000
        };
        this._success.push(success);
    }
}
