<p-toast [autoZIndex]="false" key="epx-messages" styleClass="epx-toast" [style]="{ zIndex: '9999999' }" [showTransitionOptions]="'250ms'" [hideTransitionOptions]="'250ms'">
    <ng-template let-message pTemplate="message">
        <section class="epx-toast-message">
            <section class="epx-toast-icon">
                <i [class]="message.icon"></i>
            </section>
            <section class="epx-toast-content">
                <div *ngIf="message.summary" class="pdx-toast-summary">
                    {{ message.summary | translate }}
                </div>
                <div class="epx-toast-details">
                    <span [innerHTML]="message.detail | translate"></span>
                </div>
            </section>
        </section>
    </ng-template>
</p-toast>
<epx-confirm-dialog></epx-confirm-dialog>
<epx-confirm-popup></epx-confirm-popup>
